@import "../../../node_modules/magnific-popup/dist/magnific-popup.css";
//стили страницы
@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';


.main {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;

  .animation {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 64px;
    display: block;
    max-width: 500px;
    width: 30%;

    pointer-events: none;
    @media(max-width: 1200px) {
      display: none !important;
    }
    @media (max-height: 560px) {
      display: none !important;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &--error {
    .wrapper {
      text-align: center;
      justify-content: center;
      align-items: center !important;

      h1 {
        color: $function-primary-color;
        margin-bottom: 24px;
        text-align: center;
        max-width: none;
        font-size: 72px;
        line-height: 1.5;
      }
    }
  }

  &--category {
    background-color: $theme-secondary-color;
    padding-top: 24px;
    @media (max-width: 660px) {
      padding-top: 24px;
    }

    h1 {
      margin-bottom: 56px;
      @media (max-width: 660px) {
        margin-bottom: 24px;
      }
    }


    .main__item {

      &-img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }

      &-name {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        word-break: break-word;
      }

    }

  }

  &--category-item {
    background-color: $theme-secondary-color;
    padding-top: 24px;

    h1 {
      margin-bottom: 24px;
    }

    h4 {
      margin-bottom: 56px;
    }

    .main__item {
      width: 170px;
      height: 170px;
      margin: 0 44px;

      &-img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }

      &-name {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        /* or 117% */
      }

    }
  }

  .wrapper {
    align-items: flex-start;
  }

  &__logo {
    margin-top: 48px;
    margin-bottom: 48px;

    p {
      margin-bottom: 0;
    }

    img {
      width: auto;
      height: 48px;
      margin-bottom: 24px;
    }

    @media (max-width: 660px) {
      margin-bottom: 16px;
      margin-left: auto;
      margin-right: auto;
    }

  }

  h1 {
    text-align: left;
    max-width: 600px;

  }

  h4 {
    text-align: left;
    max-width: 600px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    margin: -8px;
  }

  &__item {
    width: calc(100%/3 - 16px);
    margin: 8px;
    min-height: 170px;
    border-radius: 4px;
    padding: 16px 4px;
    background-color: $white-color;
    box-shadow: 3px 3px 20px 1px rgba(55, 68, 76, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration: none;
    @include transition();

    &:hover {
      box-shadow: 3px 3px 20px 1px rgba(55, 68, 76, 0.2);

      .main__item-img {

        path {
          fill: rgba(245, 147, 28, 1);
        }
      }
    }

    @media (max-width: 660px) {
      width: calc(50% - 16px);
    }

    &--hidden {
      pointer-events: none;
      opacity: .7;

    }
  }

  &__item-img {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;


    path {
      fill: $typography-primary-color;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item-name {
    font-size: 10px;
    font-weight: $font-weight--700;
    text-transform: uppercase;
    color: $typography-primary-color;
    width: 100%;
    text-align: center;

  }


  &.calculator {
    width: 100%;
    background-color: $theme-secondary-color;
    padding-top: 32px;
    padding-bottom: 64px;
    @media (max-width: 660px) {
      padding-top: 24px;
      padding-bottom: 32px;
    }

    form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 16px;
    }

    h1, h4 {
      width: 100%;
      margin-bottom: 24px;
      @media (max-width: 660px) {
        margin-bottom: 16px;
      }
    }

    .calculator__form, .calculator__img {
      width: calc(50% - 16px);
      @media (max-width: 660px) {
        width: 100%;
      }
    }

    .calculator__form {
      @media (max-width: 660px) {
        margin-bottom: 24px;
      }

      h4 {
        text-align: left;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }

    .calculator__form-item {
      margin-bottom: 40px;
      width: 100%;


    }

    .calculator__form-item--row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 48px;

      label {
        margin-right: 56px;
        margin-bottom: 0;

        & > span {
          height: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;
          padding-left: 0;

          img {
            display: block;
            height: 100%;
          }

          &:after {
            bottom: -24px;
            left: 50%;
            margin-left: -8px;
            top: auto;

          }


        }

      }
    }

    label {
      display: block;
      margin-bottom: 24px;
      cursor: pointer;

      & > span {
        display: block;
        position: relative;
        padding-left: 32px;
        font-size: 20px;
        line-height: 24px;

        &:after {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          border: 1px solid $function-primary-color;
          position: absolute;
          top: 4px;
          left: 0;
          z-index: 2;
          border-radius: 50%;
        }

        .subname {
          display: block;
          font-size: 14px;
        }

        .desc {
          font-size: 14px;
          display: block;
          color: $typography-secondary-color;
        }
      }

      input[type='radio'] {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + span {
          &:after {
            background-color: $function-primary-color;
          }
        }
      }
    }

    .scales-options {
      padding-bottom: 16px;
      &__text {
        font-weight: 600;
        color: $function-error-color;
      }
      .width-match {
        @include transition();
        padding: 8px 16px;
        font-size: 14px;
        color: $white-color;
        background: $function-primary-color;
        border: none;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        &:hover {
          background: $function-primary-color--hover;
        }
        &:active {
          background: $function-primary-color--active;
        }
      }
    }

    .calculator__img {

    }

    .calculator__img-inner {
      width: 100%;
      max-width: 350px;
      margin: 0 auto 32px;
      @media (max-width: 660px) {
        max-width: 200px;
      }

      img, svg {
        width: 100%;
      }
    }

    .calculator__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .total {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        text-align: center;

        &__message {
          font-size: 16px;
          font-weight: 400;
          margin: 8px 0 0;
          text-align: center;
          color: $function-primary-color;
          line-height: normal;
        }
      }

      .btn {
        padding: 16px 24px;
        background-color: $function-primary-color;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        max-width: 346px;
        cursor: pointer;

        &:hover {
          background-color: lighten($function-primary-color, 5%);
        }
      }
    }
  }

  &.order {
    padding-top: 16px;
    padding-bottom: 32px;

    .wrapper {
      flex-direction: row;
      justify-content: flex-end;
    }

    .animation {
      top: 50%;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
    }

    .order__col {
      width: 100%;
      text-align: center;
      max-width: 600px;
      font-size: 20px;
      line-height: 23px;
      @media (max-width: 1200px) {
        max-width: 100%;
      }
      @media (max-width: 660px) {
        font-size: 14px;
        line-height: 20px;
      }

      h1 {
        font-size: 24px;
        line-height: 32px;
      }

      &-item {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 10px;
        color: $typography-secondary-color;
        &--comment {
          text-align: left;
          padding: 8px;
          border: 1px solid $border-color;
        }
      }

      .circulation-input {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .field__input {
          width: 100px;
          margin: 0 8px 0 0;
        }
      }

    }

    h1 {
      font-weight: $font-weight--700;
      font-size: 40px;
      line-height: 46px;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 660px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @media (max-width: 660px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    ul {
      margin: 0;
      list-style: none;
      @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.errorlist {
          align-items: flex-start;
        }
      }

      li {
        margin-bottom: 48px;
        position: relative;
        width: auto;
        display: inline-block;
        font-size: 20px;
        line-height: 23px;
        @media (max-width: 1200px) {
          margin-bottom: 16px;
        }
        @media (max-width: 660px) {
          font-size: 14px;
          line-height: 20px;
        }


        &:after {
          content: '';
          position: absolute;
          top: calc(100% + 8px);
          left: 50%;
          height: 32px;
          width: 2px;
          background-image: url(../img/svg/Vector1.svg);
          background-size: contain;
          @media (max-width: 1200px) {
            display: none;
          }
        }

        &:before {
          content: '';
          position: absolute;
          left: -16px;
          top: 8px;
          width: 8px;
          height: 8px;
          background: #FF8300;
        }

        &:last-of-type {
          margin-bottom: 8px;

          &:after {
            display: none;
          }
        }
      }
    }

    .order__form {
      margin-top: 20px;
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      border: 2px solid $border-color;

      &-col {
        padding: 0 16px;
        width: 50%;
        margin: 0 auto;
        @media (max-width: 660px) {
          width: 100%;
        }

        &--full {
          width: 100%;
          max-width: 392px;
        }

        .field-file {
          margin-bottom: 16px;
        }

      }

      .field {
        text-align: left;

        ul li {
          font-size: 14px;
          line-height: 16px;
        }

        textarea {
          height: 102px;
          resize: none;
        }
      }

      .calc-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px;
        @media(max-width: 660px) {
          flex-direction: column;
          align-items: center;
        }
        .field {
          width: calc(50% - 8px);
          @media(max-width: 660px) {
            width: 100%;
            max-width: 346px;
          }
        }
        .btn {
          @media(max-width: 660px) {
            margin: 16px auto 0;
          }
        }
      }
    }

    .policy {
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      padding: 0 24px;
    }

    .btn {
      width: 100%;
      max-width: 346px;
      margin: 16px auto 0;
      background: #FF8300;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background-color: lighten(#FF8300, 5%);
      }

      @media (max-width: 660px) {
        margin: 16px 24px 0;
      }

    }

    .category-comment {
      padding: 16px 16px 0;
      p {
        text-align: left;
        font-size: 1.75rem;
        margin-bottom: 8px;
      }
    }
  }


}

.mfp-close {
  font-size: 44px;
}