@charset "UTF-8";
@import url(../../../node_modules/magnific-popup/dist/magnific-popup.css);
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
.main {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%; }
  .main .animation {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 64px;
    display: block;
    max-width: 500px;
    width: 30%;
    pointer-events: none; }
    @media (max-width: 1200px) {
      .main .animation {
        display: none !important; } }
    @media (max-height: 560px) {
      .main .animation {
        display: none !important; } }
    .main .animation img {
      display: block;
      width: 100%; }
  .main--error .wrapper {
    text-align: center;
    justify-content: center;
    align-items: center !important; }
    .main--error .wrapper h1 {
      color: #FF8300;
      margin-bottom: 24px;
      text-align: center;
      max-width: none;
      font-size: 72px;
      line-height: 1.5; }
  .main--category {
    background-color: #FAFAFA;
    padding-top: 24px; }
    @media (max-width: 660px) {
      .main--category {
        padding-top: 24px; } }
    .main--category h1 {
      margin-bottom: 56px; }
      @media (max-width: 660px) {
        .main--category h1 {
          margin-bottom: 24px; } }
    .main--category .main__item-img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px; }
    .main--category .main__item-name {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      word-break: break-word; }
  .main--category-item {
    background-color: #FAFAFA;
    padding-top: 24px; }
    .main--category-item h1 {
      margin-bottom: 24px; }
    .main--category-item h4 {
      margin-bottom: 56px; }
    .main--category-item .main__item {
      width: 170px;
      height: 170px;
      margin: 0 44px; }
      .main--category-item .main__item-img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px; }
      .main--category-item .main__item-name {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        /* or 117% */ }
  .main .wrapper {
    align-items: flex-start; }
  .main__logo {
    margin-top: 48px;
    margin-bottom: 48px; }
    .main__logo p {
      margin-bottom: 0; }
    .main__logo img {
      width: auto;
      height: 48px;
      margin-bottom: 24px; }
    @media (max-width: 660px) {
      .main__logo {
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto; } }
  .main h1 {
    text-align: left;
    max-width: 600px; }
  .main h4 {
    text-align: left;
    max-width: 600px; }
  .main__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    margin: -8px; }
  .main__item {
    width: calc(100%/3 - 16px);
    margin: 8px;
    min-height: 170px;
    border-radius: 4px;
    padding: 16px 4px;
    background-color: #ffffff;
    box-shadow: 3px 3px 20px 1px rgba(55, 68, 76, 0.08);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration: none;
    transition: all 0.2s ease-in-out 0s; }
    .main__item:hover {
      box-shadow: 3px 3px 20px 1px rgba(55, 68, 76, 0.2); }
      .main__item:hover .main__item-img path {
        fill: #f5931c; }
    @media (max-width: 660px) {
      .main__item {
        width: calc(50% - 16px); } }
    .main__item--hidden {
      pointer-events: none;
      opacity: .7; }
  .main__item-img {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .main__item-img path {
      fill: #000; }
    .main__item-img img {
      display: block;
      width: 100%; }
  .main__item-name {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    width: 100%;
    text-align: center; }
  .main.calculator {
    width: 100%;
    background-color: #FAFAFA;
    padding-top: 32px;
    padding-bottom: 64px; }
    @media (max-width: 660px) {
      .main.calculator {
        padding-top: 24px;
        padding-bottom: 32px; } }
    .main.calculator form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 16px; }
    .main.calculator h1, .main.calculator h4 {
      width: 100%;
      margin-bottom: 24px; }
      @media (max-width: 660px) {
        .main.calculator h1, .main.calculator h4 {
          margin-bottom: 16px; } }
    .main.calculator .calculator__form, .main.calculator .calculator__img {
      width: calc(50% - 16px); }
      @media (max-width: 660px) {
        .main.calculator .calculator__form, .main.calculator .calculator__img {
          width: 100%; } }
    @media (max-width: 660px) {
      .main.calculator .calculator__form {
        margin-bottom: 24px; } }
    .main.calculator .calculator__form h4 {
      text-align: left;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 24px; }
    .main.calculator .calculator__form-item {
      margin-bottom: 40px;
      width: 100%; }
    .main.calculator .calculator__form-item--row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 48px; }
      .main.calculator .calculator__form-item--row label {
        margin-right: 56px;
        margin-bottom: 0; }
        .main.calculator .calculator__form-item--row label > span {
          height: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;
          padding-left: 0; }
          .main.calculator .calculator__form-item--row label > span img {
            display: block;
            height: 100%; }
          .main.calculator .calculator__form-item--row label > span:after {
            bottom: -24px;
            left: 50%;
            margin-left: -8px;
            top: auto; }
    .main.calculator label {
      display: block;
      margin-bottom: 24px;
      cursor: pointer; }
      .main.calculator label > span {
        display: block;
        position: relative;
        padding-left: 32px;
        font-size: 20px;
        line-height: 24px; }
        .main.calculator label > span:after {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          border: 1px solid #FF8300;
          position: absolute;
          top: 4px;
          left: 0;
          z-index: 2;
          border-radius: 50%; }
        .main.calculator label > span .subname {
          display: block;
          font-size: 14px; }
        .main.calculator label > span .desc {
          font-size: 14px;
          display: block;
          color: #9C9C9C; }
      .main.calculator label input[type='radio'] {
        position: absolute;
        z-index: -1;
        opacity: 0; }
        .main.calculator label input[type='radio']:checked + span:after {
          background-color: #FF8300; }
    .main.calculator .scales-options {
      padding-bottom: 16px; }
      .main.calculator .scales-options__text {
        font-weight: 600;
        color: #eb5757; }
      .main.calculator .scales-options .width-match {
        transition: all 0.2s ease-in-out 0s;
        padding: 8px 16px;
        font-size: 14px;
        color: #ffffff;
        background: #FF8300;
        border: none;
        border-radius: 4px;
        outline: none;
        cursor: pointer; }
        .main.calculator .scales-options .width-match:hover {
          background: #ff8f1a; }
        .main.calculator .scales-options .width-match:active {
          background: #e67600; }
    .main.calculator .calculator__img-inner {
      width: 100%;
      max-width: 350px;
      margin: 0 auto 32px; }
      @media (max-width: 660px) {
        .main.calculator .calculator__img-inner {
          max-width: 200px; } }
      .main.calculator .calculator__img-inner img, .main.calculator .calculator__img-inner svg {
        width: 100%; }
    .main.calculator .calculator__btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .main.calculator .calculator__btn .total {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        text-align: center; }
        .main.calculator .calculator__btn .total__message {
          font-size: 16px;
          font-weight: 400;
          margin: 8px 0 0;
          text-align: center;
          color: #FF8300;
          line-height: normal; }
      .main.calculator .calculator__btn .btn {
        padding: 16px 24px;
        background-color: #FF8300;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        max-width: 346px;
        cursor: pointer; }
        .main.calculator .calculator__btn .btn:hover {
          background-color: #ff8f1a; }
  .main.order {
    padding-top: 16px;
    padding-bottom: 32px; }
    .main.order .wrapper {
      flex-direction: row;
      justify-content: flex-end; }
    .main.order .animation {
      top: 50%;
      bottom: auto;
      left: 0;
      transform: translateY(-50%); }
    .main.order .order__col {
      width: 100%;
      text-align: center;
      max-width: 600px;
      font-size: 20px;
      line-height: 23px; }
      @media (max-width: 1200px) {
        .main.order .order__col {
          max-width: 100%; } }
      @media (max-width: 660px) {
        .main.order .order__col {
          font-size: 14px;
          line-height: 20px; } }
      .main.order .order__col h1 {
        font-size: 24px;
        line-height: 32px; }
      .main.order .order__col-item {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 10px;
        color: #9C9C9C; }
        .main.order .order__col-item--comment {
          text-align: left;
          padding: 8px;
          border: 1px solid #e9e9e9; }
      .main.order .order__col .circulation-input {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px; }
        .main.order .order__col .circulation-input .field__input {
          width: 100px;
          margin: 0 8px 0 0; }
    .main.order h1 {
      font-weight: 700;
      font-size: 40px;
      line-height: 46px;
      text-align: center;
      margin-bottom: 20px; }
      @media (max-width: 660px) {
        .main.order h1 {
          font-size: 24px;
          line-height: 32px; } }
    .main.order h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px; }
      @media (max-width: 660px) {
        .main.order h2 {
          font-size: 18px;
          line-height: 24px; } }
    .main.order ul {
      margin: 0;
      list-style: none; }
      @media (max-width: 1200px) {
        .main.order ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .main.order ul.errorlist {
            align-items: flex-start; } }
      .main.order ul li {
        margin-bottom: 48px;
        position: relative;
        width: auto;
        display: inline-block;
        font-size: 20px;
        line-height: 23px; }
        @media (max-width: 1200px) {
          .main.order ul li {
            margin-bottom: 16px; } }
        @media (max-width: 660px) {
          .main.order ul li {
            font-size: 14px;
            line-height: 20px; } }
        .main.order ul li:after {
          content: '';
          position: absolute;
          top: calc(100% + 8px);
          left: 50%;
          height: 32px;
          width: 2px;
          background-image: url(../img/svg/Vector1.svg);
          background-size: contain; }
          @media (max-width: 1200px) {
            .main.order ul li:after {
              display: none; } }
        .main.order ul li:before {
          content: '';
          position: absolute;
          left: -16px;
          top: 8px;
          width: 8px;
          height: 8px;
          background: #FF8300; }
        .main.order ul li:last-of-type {
          margin-bottom: 8px; }
          .main.order ul li:last-of-type:after {
            display: none; }
    .main.order .order__form {
      margin-top: 20px;
      width: 100%;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      border: 2px solid #e9e9e9; }
      .main.order .order__form-col {
        padding: 0 16px;
        width: 50%;
        margin: 0 auto; }
        @media (max-width: 660px) {
          .main.order .order__form-col {
            width: 100%; } }
        .main.order .order__form-col--full {
          width: 100%;
          max-width: 392px; }
        .main.order .order__form-col .field-file {
          margin-bottom: 16px; }
      .main.order .order__form .field {
        text-align: left; }
        .main.order .order__form .field ul li {
          font-size: 14px;
          line-height: 16px; }
        .main.order .order__form .field textarea {
          height: 102px;
          resize: none; }
      .main.order .order__form .calc-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px; }
        @media (max-width: 660px) {
          .main.order .order__form .calc-block {
            flex-direction: column;
            align-items: center; } }
        .main.order .order__form .calc-block .field {
          width: calc(50% - 8px); }
          @media (max-width: 660px) {
            .main.order .order__form .calc-block .field {
              width: 100%;
              max-width: 346px; } }
        @media (max-width: 660px) {
          .main.order .order__form .calc-block .btn {
            margin: 16px auto 0; } }
    .main.order .policy {
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      padding: 0 24px; }
    .main.order .btn {
      width: 100%;
      max-width: 346px;
      margin: 16px auto 0;
      background: #FF8300;
      border-radius: 2px;
      cursor: pointer; }
      .main.order .btn:hover {
        background-color: #ff8f1a; }
      @media (max-width: 660px) {
        .main.order .btn {
          margin: 16px 24px 0; } }
    .main.order .category-comment {
      padding: 16px 16px 0; }
      .main.order .category-comment p {
        text-align: left;
        font-size: 1.75rem;
        margin-bottom: 8px; }

.mfp-close {
  font-size: 44px; }
